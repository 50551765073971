// WaitlistModal.jsx
import React, { useState } from 'react';
import { IoIosClose } from "react-icons/io";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from '../CheckoutForm/CheckoutForm';
import JoinedWaitlist from '../JoinedWaitlist/JoinedWaitlist';
import OfferDetails from '../OfferDetails/OfferDetails';
import Success from '../Success/Success';
import styles from './WaitlistModal.module.css';
import waitlistService from '../../api/service/waitlist';

const YOUR_BACKEND_ENDPOINT = "http://localhost:4242";
const stripePromise = loadStripe('pk_test_51Q8mBaG80OmZHOKvhtuDsGUbknTdDvaZszt5ZyKpgx08uvBuWnGudPYzrTppNTybEh5VstMyyPOLeAhgbZjnPEAJ00qvttsQkf');

const WaitlistModal = ({ buttonLabel = "Join waitlist", size = "medium" }) => {
  const [isWaitlistOpen, setIsWaitlistOpen] = useState(false);
  const [isJoinedWaitlistOpen, setIsJoinedWaitlistOpen] = useState(false);
  const [isOfferDetailsOpen, setIsOfferDetailsOpen] = useState(false);
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [callbackData, setCallbackData] = useState(null);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    country: "",
    whatsappNumber: ""
  });

  const [errors, setErrors] = useState({});
  const [responseMessage, setResponseMessage] = useState(null);

  const handleChange = (e) => {
    // setFormData({ ...formData, [e.target.name]: e.target.value });
    const {name, value } = e.target;
    setFormData({...formData, [name] : value});
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First name is required.";
    if (!formData.country) newErrors.lastName = "Country is required.";
    if (!formData.email) {
      newErrors.email = "Email address is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email address is invalid.";
    }
    if (!formData.whatsappNumber) {
      newErrors.whatsappNumber = "Phone number is required.";
    } else if (!/^\+?[0-9]{10,13}$/.test(formData.whatsappNumber)) {
      newErrors.whatsappNumber = "Phone number is invalid. Enter 10-13 digits, optionally with +.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const openWaitlist = () => setIsWaitlistOpen(true);
  const closeAllModals = () => {
    setIsWaitlistOpen(false);
    setIsJoinedWaitlistOpen(false);
    setIsOfferDetailsOpen(false);
    setIsCheckoutOpen(false);
    setIsSuccessOpen(false);
  };

  const handleWaitlistSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await waitlistService.createWaitlist(formData);
      setResponseMessage("Record Submitted");
      console.log("Response", response);


    } catch (error) {
      setResponseMessage("Record failed to submit");
      console.log("Error", error);
    }

    if (!validateForm()) return; // Stop submission if there are validation errors
    // Process waitlist submission
    setIsWaitlistOpen(false);
    setIsJoinedWaitlistOpen(true); // Open JoinedWaitlist after submitting waitlist
  };

  const handleJoinedWaitlistSubmit = () => {
    setIsJoinedWaitlistOpen(false);
    setIsOfferDetailsOpen(true); // Open OfferDetails after JoinedWaitlist
  };

  const handleOfferDetailsSubmit = async () => {
    try {
      // Fetch clientSecret for Stripe payment
      const response = await fetch(`${YOUR_BACKEND_ENDPOINT}/create-payment-intent`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...formData, price: 19999 }),
      });
      const data = await response.json();
      setClientSecret(data.clientSecret);
      setIsOfferDetailsOpen(false);
      setIsCheckoutOpen(true); // Open CheckoutForm modal after OfferDetails
    } catch (error) {
      console.error('Error fetching clientSecret:', error);
    }
  };

  const handleCheckoutSubmit = () => {
    setIsCheckoutOpen(false);
    setIsSuccessOpen(true); // Open Success modal after CheckoutForm
  };

  return (
    <div>
      <button onClick={openWaitlist} className={`${styles.joinButton} ${styles[size]}`}>Join waitlist</button>

      {isWaitlistOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <button onClick={() => setIsWaitlistOpen(false)} className={styles.closeButton}><IoIosClose /></button>
            <h2 className={styles.BoldText}>Join the waitlist</h2>
            <p className={styles.SmText}>Enter your details</p>
            <form onSubmit={handleWaitlistSubmit}>
      <div className={styles.inputCont}>
        <div className={styles.inputField}>
          <label htmlFor="fullName">Fullname</label>
          <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} />
        </div>
        {errors.fullName && <span className={styles.error}>{errors.fullName}</span>}
      </div>

      <div className={styles.inputCont}>
        <div className={styles.inputField}>
          <label htmlFor="email">Email address</label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} />
        </div>
        {errors.email && <span className={styles.error}>{errors.email}</span>}
      </div>

      <div className={styles.inputCont}>
        <div className={styles.inputField}>
          <label htmlFor="country">Country</label>
          <input type="text" name="country" value={formData.country} onChange={handleChange} />
        </div>
        {errors.country && <span className={styles.error}>{errors.country}</span>}
      </div>

      <div className={styles.inputCont}>
        <div className={styles.inputField}>
          <label htmlFor="whatsappNumber">Phone</label>
          <input
            type="text"
            name="whatsappNumber"
            value={formData.whatsappNumber}
            onChange={handleChange}
            placeholder='+1234567890'
          />
        </div>
        {errors.whatsappNumber && <span className={styles.error}>{errors.whatsappNumber}</span>}
      </div>
      
      <button type="submit" className={styles.paymentButton}>Submit details</button>
      {responseMessage && <p>{responseMessage}</p>}
</form>
          </div>
        </div>
      )}

      {isJoinedWaitlistOpen && (
        <JoinedWaitlist onClose={() => setIsJoinedWaitlistOpen(false)} onSubmit={handleJoinedWaitlistSubmit} firstName={formData.firstName}/>
      )}

      {isOfferDetailsOpen && (
        <OfferDetails onClose={() => setIsOfferDetailsOpen(false)} onSubmit={handleOfferDetailsSubmit} lifetime={'Get lifetime offer for $199.99'}/>
      )}

      {isCheckoutOpen && clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm clientSecret={clientSecret} onClose={() => setIsCheckoutOpen(false)} onSubmit={handleCheckoutSubmit} />
        </Elements>
      )}

      {isSuccessOpen && <Success onClose={closeAllModals} />}
    </div>
  );
};

export default WaitlistModal;
