import React from 'react'
import styles from './Languages.module.css'
const Languages = () => {
  return (
    <div className={styles.LanguagesContainer}>
      <div className={styles.Languages}></div>
      </div>
    
  )
}

export default Languages