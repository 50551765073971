import React from 'react';
import styles from './Success.module.css'; // Importing the module CSS
import icon from "../../images/Icon.png"
import { IoIosClose } from "react-icons/io";


const Success = ({ clientSecret, onClose }) => {
  return (
    <div className={styles.checkoutOverlay}>
      <div className={styles.checkoutContent}>
        <button className={styles.closeButton} onClick={onClose}>
          <IoIosClose />
        </button>
        <img src={icon} className={styles.icon}/>
        <h2 className={styles.header}>Congratulations!</h2>
        <p className={styles.paymentInfo}>Hi Dennis, You now have lifetime access our exclusive community. Please keep an eye out for our emails and updates regarding the progress and activities of the community.        </p>
        <button className={styles.paymentButton} onClick={onClose}>
         Back to Home
     </button>
      </div>
    </div>
  )
  // return (
  //   <div className={styles.successContainer}>
  //     <div className={styles.card}>
  //       <div className={styles.icon}>
  //         <svg
  //           xmlns="http://www.w3.org/2000/svg"
  //           viewBox="0 0 24 24"
  //           fill="none"
  //           stroke="currentColor"
  //           strokeWidth="2"
  //           strokeLinecap="round"
  //           strokeLinejoin="round"
  //           className={styles.checkmark}
  //         >
  //           <path d="M20 6L9 17l-5-5" />
  //         </svg>
  //       </div>
  //       <h1 className={styles.title}>Congratulations!</h1>
  //       <p className={styles.message}>
  //       Hi Dennis, You now have lifetime access our exclusive community. Please keep an eye out for our emails and updates regarding the progress and activities of the community.
  //       </p>
  //       <button className={styles.button} onClick={() => window.location.href = '/'}>
  //         Back to Home
  //       </button>
  //     </div>
  //   </div>
  // );
};

export default Success;