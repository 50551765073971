import React from 'react';
import styles from './VideoModal.module.css';
// import vid from '../../images/'

const VideoModal = ({ onClose }) => {
  return (
    <div className={styles.modalBackdrop}>
      <div className={styles.modalContent}>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        <video controls className={styles.videoPlayer}>
          {/* <source src='https://www.youtube.com/shorts/wcLnSsa94E8' type="video/mp4" /> */}
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/wcLnSsa94E8"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default VideoModal;
