import makeRequest from "../helpers/request";

const waitlistService = {
    createWaitlist: async (data) =>{
        try {
            const url = "/waitlist";
            const {data: responseData} = await makeRequest.post(url, data);
            return responseData;

        } catch (error) {
            throw error;
        }
    }
}
export default waitlistService;