import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import styles from './CheckoutForm.module.css';
import { IoIosClose } from "react-icons/io";

const CheckoutForm = ({ clientSecret, onClose, onSubmit }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);

    // const history = useHistory();

    // Function to handle going back
    // const handleBack = () => {
      // const history = useHistory();
  // history.push(-1);
      // history.push(-1); // Go to the previous page
    // };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      setLoading(true);
      
      const { error } = await stripe.confirmPayment({ elements, confirmParams: { return_url: "http://localhost:3000/success" } });
      if (!error) onSubmit(); // Move to success modal if payment is successful
  
      setLoading(false);
    };


return (
    <div className={styles.checkoutOverlay}>
      <div className={styles.checkoutContent}>
        <button className={styles.closeButton} onClick={onClose}>
          <IoIosClose />
        </button>
        <h2 className={styles.header}>Join the waitlist</h2>
        <p className={styles.paymentInfo}>Enter your details</p>
        <button >Back</button>         {/* Insert Stripe Payment Elements */}
        <form onSubmit={handleSubmit}>
       <PaymentElement />
       <br/>
        <button type="submit" disabled={!stripe || loading} className={styles.paymentButton}>
          {loading ? 'Processing...' : 'Pay Now'}
        </button>
        {/* {error && <div className={styles.error}>{error}</div>} */}
      </form>
      </div>
    </div>
  );
};

export default CheckoutForm;
