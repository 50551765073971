import React from 'react'
import styles from "./OfferDetails.module.css"
import { IoIosClose } from "react-icons/io";


const OfferDetails = ({ onClose, onSubmit, lifetime}) => {
  return (
    <div className={styles.checkoutOverlay}>
      <div className={styles.checkoutContent}>
        <button className={styles.closeButton} onClick={onClose}>
          <IoIosClose />
        </button>
        <h2 className={styles.header}>Embrace the lifetime advantage - Pay once, learn forever with Atarodo</h2>
        <p className={styles.paymentInfo}>Unlock lifetime access to Atarodo for $199 (a limited 30% discount!) and embark on a unique journey of African language learning and cultural discovery.</p>
        
        <div className={styles.Advantages}>
            <h4 className={styles.offerInfo}>Here’s why this one-time offer is unbeatable:</h4>
            <div className={styles.item}>
                <p className={styles.title}>All Access, Forever</p>
                <p className={styles.subtitle}>A single payment gives you uninterrupted access to all Atarodo’s content—no renewals, no extra fees.</p>
            </div>
            <div className={styles.item}>
                <p className={styles.title}>Flashcards & Games for Mastery</p>
                <p className={styles.subtitle}>Build essential vocabulary with thousands of flashcards and make practice fun with interactive games that reinforces learning.</p>
            </div>
            <div className={styles.item}>
                <p className={styles.title}>Exclusive Monthly Live Sessions</p>
                <p className={styles.subtitle}>Gain insights from top African cultural icons in monthly live classes.</p>
            </div>
            <div className={styles.item}>
                <p className={styles.title}>Monthly Book Review</p>
                <p className={styles.subtitle}>Be part of our monthly book reviews by different authors and hosts.</p>
            </div>
            <div className={styles.item}>
                <p className={styles.title}>Expanding Language Library</p>
                <p className={styles.subtitle}>Start with one language and access many more – Yoruba, Igbo, Twi, Hausa, Swahili, Afrikaans, and more. Every language we add is yours to explore.</p>
            </div>
            <div className={styles.item}>
                <p className={styles.title}>Discounts on African Inspired Merchandize</p>
                <p className={styles.subtitle}>Enjoy special marketplace discounts on authentic African inspired merchandise.</p>
            </div>
            <div className={styles.item}>
                <p className={styles.title}>Gamified Learning with Rewards</p>
                <p className={styles.subtitle}>Celebrate your progress with points, badges, and rewards that keep learning exciting.</p>
            </div>
        </div>
        <button onClick={onSubmit} className={styles.paymentButton}>
          {lifetime}
        </button>
      </div>
    </div>
  )
}

export default OfferDetails