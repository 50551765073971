import React, { useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'; // Import the chevron icon

import styles from "./Faq.module.css"

const Faq = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleAccordionClick = (index) => {
      setActiveIndex(activeIndex === index ? null : index); // Toggle active accordion
    };
  
    const accordionData = [
      { title: 'What age groups is Atarodo for?', content: 'Atarodo is designed for learners of all ages! Whether you’re a child eager to learn your mother tongue, a teenager exploring your roots, or an adult with an interest in the Yoruba language and culture, our engaging content is tailored to suit everyone from beginners to proficient speakers.' },
      { title: 'What do I get with the lifetime subscription?', content: 'With your lifetime subscription, you gain full access to all our video lessons, interactive quizzes, cultural insights, monthly live classes with guest speakers, community features, rewards system, and exclusive content. Its a comprehensive package that enhances your learning experience!' },
      { title: 'How long will I have access to the lessons?', content: 'Your lifetime subscription grants you unlimited access to all lessons and content on the Atarodo platform for as long as you maintain your subscription. This means you can learn at your own pace and revisit any lessons whwenever you want.' },
      { title: 'How often is new content added to the platform?', content: 'Atarodo regularly updates its content with new language courses, flashcards, and cultural resources provideing fresh, engageing material to enrich your learning experience.' },
      { title: 'What makes Atarodo different from other language-learning platforms?', content: 'Atarodo goes beyond language instruction by immersing users in authentic African cultural experience, including folktales, history, and traditional arts. Our platform celebrates Africa diversity, ensuring users not only learn the language but also understand its cultural context.' },
      { title: 'Are there any prerequisites for joining Atarodo’s courses?', content: 'No prerequisites are required! Our platform is designed for beginners to advanced learners, with modules suitable for different skill levels. Whether you are just starting or looking to deepen your knowledge, Atarodo accommodates your needs.' },
      { title: 'Can I access Atarodo from multiple devices?', content: 'Yes! Atarodo is accessible across various devices, including smartphones, tablets, and computers,so you can learn whenever and wherever its convenient for you.' },
      { title: 'What happens if I need to pause my learning for a while?', content: 'No worries! With lifetime access, you can learn at your own pace. If you need to take a break, you can pick up right wher you left off, with your progress and achievements saved in your profile.' },
      { title: 'What does lifetime access to Atarodo include?', content: 'Lifetime access includes all current and future language courses, cultural content, and resources on Atarodo. As new languages, flashcards, games, and interactive lessons are added, you will have full access without any extra charges.' },
      { title: 'How are Atarodo’s flashcards structured for language learning?', content: 'Your lifetime subscription grants you unlimited access to all lessons and content on the Atarodo platform for as long as you maintain your subscription. This means you can learn at your own pace and revisit any lessons whwenever you want.' },
      { title: 'Will I get support if I have questions or need help with the lessons?', content: 'Your lifetime subscription grants you unlimited access to all lessons and content on the Atarodo platform for as long as you maintain your subscription. This means you can learn at your own pace and revisit any lessons whwenever you want.' },
      { title: 'How does Atarodo incorporate African culture into the language courses?', content: 'Your lifetime subscription grants you unlimited access to all lessons and content on the Atarodo platform for as long as you maintain your subscription. This means you can learn at your own pace and revisit any lessons whwenever you want.' },
      { title: 'Can I track my progress or set personal learning goals on Atarodo?', content: 'Your lifetime subscription grants you unlimited access to all lessons and content on the Atarodo platform for as long as you maintain your subscription. This means you can learn at your own pace and revisit any lessons whwenever you want.' },




    ];

    
  return (
    <div className={styles.FaqContainer}>
        <h2 className={styles.BoldText}>Got Questions?</h2>
        <p className={styles.SmText}>We’ve got answers.</p>

        <div className={styles.accordionContainer}>
      {accordionData.map((item, index) => (
        <div key={index} className={styles.accordionItem}>
          <button
            className={classNames(styles.accordionTitle, { [styles.active]: activeIndex === index })}
            onClick={() => handleAccordionClick(index)}
          >
            {item.title}
            {/* Icon */}
            <span className={classNames(styles.icon, { [styles.rotate]: activeIndex === index })}>
              <FontAwesomeIcon icon={faChevronDown} />
            </span>
          </button>
          <div
            className={classNames(styles.accordionContent, { [styles.open]: activeIndex === index })}
          >
            {item.content}
          </div>
        </div>
      ))}
    </div>

    </div>
  )
}

export default Faq