import React from 'react'
import icon from "../../../src/images/Icon.png"
import styles from "./JoinedWaitlist.module.css"
import { IoIosClose } from "react-icons/io";


const JoinedWaitlist = ({ onClose, onSubmit, firstName }) => {
  return (
    <div className={styles.checkoutOverlay}>
      <div className={styles.checkoutContent}>
        <button className={styles.closeButton} onClick={onClose}>
          <IoIosClose />
        </button>
        <img src={icon} className={styles.icon}/>
        <h2 className={styles.header}>You have joined the waitlist</h2>
        <p className={styles.paymentInfo}>Hi {firstName}, Thank you for joining the waitlist. You are now a part of our exclusive community.</p>
        <button type="submit" onClick={onSubmit} className={styles.paymentButton}>
          Get lifetime offer for $199.99
        </button>
      </div>
    </div>
  )
}

export default JoinedWaitlist